<template
  ><div class="page">
    <div
      class="page-header-with-title"
      :style="{ 'background-image': 'url(' + headerImage + ')' }"
    >
      <h1>
        Account
      </h1>
    </div>
    <Content>
      <FlexContainer padding="normal" justify-content="center">
        <FlexItem width="25%" padding="normal">
          <FlexContainer padding="normal" flex-direction="column" force-padding>
            <FlexItem padding="small" text-align="center">
              <figure>
                <img v-if="currentUser.photoURL" :src="currentUser.photoURL" />
                <img
                  v-else
                  src="~@/assets/images/profile-female.svg"
                  height="100"
                />
              </figure>
            </FlexItem>
            <FlexItem padding="small" text-align="center">
              <h2>{{ currentUser.name }}</h2>
              <Pill>5 pts</Pill>
            </FlexItem>
            <FlexItem padding="normal">
              <ButtonLink
                type="error"
                full-width="true"
                @click.native="resetUser"
              >
                Reset User
              </ButtonLink>
              <ButtonLink
                type="secondary"
                full-width="true"
                @click.native="signOut"
              >
                Logout
              </ButtonLink>
            </FlexItem>
          </FlexContainer>
        </FlexItem>
      </FlexContainer>
    </Content>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      headerImage: require('@/assets/images/headers/my-profile.svg')
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.currentUser
    })
  },
  methods: {
    ...mapActions({
      signOut: 'user/signOut',
      resetUser: 'user/resetUser'
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/page-header';
.circle {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-primary;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
</style>
